import type { PromoMetaProps } from '@royalaholddelhaize/ah-analytics';
import {
    AH_UI_CMS_TESTHOOKS,
    createTestHook,
} from '@royalaholddelhaize/ah-test-hooks';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import type { ContainerProps } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container.interfaces';
import {
    Grid,
    GridItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/layout/grid/grid';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import {
    ContentCmsComponentType,
    ContentImageVariantType,
} from '../../graphql/_generated-graphql-types';
import { convertToTitleCase } from '../../helpers/convert-to-title-case';
import { getImageVariant } from '../../helpers/get-image-variant.helper';
import { typographyTagFromTaglevel } from '../../helpers/tag-level';
import { ScrollToAnchorId } from '../scroll-to-anchor-id';
import { SmallPromoBlock } from './elements/small-promo-block/small-promo-block';
import type { StickerType } from './elements/sticker/sticker';
import { normalizeImage } from './helpers/image-normalizer';
import type { ShopInShopProps } from './shop-in-shop.interface';

import css from './shop-in-shop.module.scss';

/**
 * @deprecated Will be replaced with the spotlight list
 */
export const ShopInShop: FC<
    ShopInShopProps & Pick<ContainerProps, 'maxWidth' | 'className'>
> = ({
    anchorId,
    id,
    title,
    tagLevel,
    items,
    documentUUID,
    maxWidth,
    className,
}) => {
    if (!items || items.length === 0) {
        return null;
    }

    return (
        <Container as="section" maxWidth={maxWidth} className={className}>
            {title && (
                <Typography
                    key={id}
                    as={typographyTagFromTaglevel(tagLevel || 2)}
                    variant="heading-1"
                    className={css.title}
                >
                    {title}
                </Typography>
            )}
            <ScrollToAnchorId anchorId={anchorId}>
                <Grid
                    {...createTestHook(AH_UI_CMS_TESTHOOKS.SHOP_IN_SHOP_LANE)}
                >
                    {items
                        .filter(e => !!e)
                        .map((item, idx) => {
                            const promotionId = `${id}_${idx + 1}`;
                            const {
                                title: itemTitle,
                                link,
                                sticker,
                                bgColor,
                                images,
                            } = item;

                            const promoMeta: PromoMetaProps = {
                                id: documentUUID,
                                type: 'Bloomreach CMS',
                                href: images?.variants
                                    ? getImageVariant(
                                          images.variants,
                                          ContentImageVariantType.SMALL,
                                      ) || undefined
                                    : undefined,
                                title: itemTitle || '',
                                promoListType: convertToTitleCase(
                                    ContentCmsComponentType.SHOP_IN_SHOP,
                                ),
                                promoListName: title || '',
                                position: idx + 1,
                            };

                            return (
                                <GridItem
                                    key={promotionId}
                                    xs={4}
                                    md={6}
                                    xl={4}
                                >
                                    <SmallPromoBlock
                                        title={itemTitle || ''}
                                        titleTagLevel={3}
                                        href={link?.href || ''}
                                        link={link?.title || ''}
                                        target={link?.target || '_top'}
                                        promoSticker={sticker as StickerType}
                                        bgColor={bgColor || 'ah'}
                                        promoImages={
                                            item && normalizeImage(item)
                                        }
                                        cidPromo={promoMeta}
                                    />
                                </GridItem>
                            );
                        })}
                </Grid>
            </ScrollToAnchorId>
        </Container>
    );
};
